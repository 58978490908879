import { Session } from './session';



export default class Helper {
	static fetchError(url, e, setFieldError) {
		console.error(url, e);
		if (e.response.status === 422) {
			const errors = e.response.data.status.message
			const errorKeys = Object.keys(errors)
			errorKeys.forEach((key, index) => {
				errors[key].map((v, i, a) => {
					setFieldError(key, v)
				})
			})
		} else {
			alert(e)
		}
	}

	static set(key, value) {
		const _key = (process.env.REACT_APP_KEY + "_" + key)
		localStorage.setItem(_key, value)
	}

	static get(key) {
		const _key = (process.env.REACT_APP_KEY + "_" + key)
		return localStorage.getItem(_key)
	}

	static guard() {
		const token = Session.get('accessToken')
		return (token !== null && token !== undefined && token !== 'null') ? true : false;
	}

	static logout() {
		this.set('accessToken', null);
		this.set('countingAnonymous', null);
		this.set('_disableNotifCovid',false)
		// window.location.href = "/";
		this.redirectTo("login")
	}

	static getStatusIndex(status){
		if(status === "Menunggu Barang Dikirim") return 1;
		if(status === "Validasi Order") return 2;
		if(status === "Menunggu Pembayaran") return 3;
		if(status === "Pengurusan Dokumen") return 4;
		if(status === "Dikirim") return 5;
		if(status === "Diterima") return 6;
		return 0;
	}

	static formatMoney(amount) {
		const numbro = require('numbro');
		return "Rp" + numbro(amount).format({ thousandSeparated: true });
	}

	static getVolume(long, tall, wide, quantity) {
		const magicNumber = 5500;
		let total = (parseFloat(long) * parseFloat(tall) * parseFloat(wide) / parseFloat(magicNumber) * parseFloat(quantity));
		return total.toFixed(2);
	}

	static getWeight(weight, quantity){
		let total = parseFloat(weight) * parseFloat(quantity);
		return total.toFixed(2)		
	}

	static redirectTo(path){
		window.location.href = process.env.REACT_APP_PATH_NAME+"/"+path
	}

	static routeTo(history,path){
		// window.location.href = process.env.REACT_APP_PATH_NAME+"/"+path
		// history.push(process.env.REACT_APP_PATH_NAME+path)
		history.push(path)
	}

	static soon(){
		this.redirectTo('soon')
	}

	static getIsNeedRefreshToken() {
		if (this.guard()) {
			let maxDiff = 6000; // 10 menit
			let current = Math.floor(Date.now() / 1000);
			let token = Helper.get('tokenUpdateAt');
			let diff = current - token;
			if (diff >= maxDiff) return true;
			return false;
		}
		return false;
	}
	static generateLinkKonfirmasi(order_no, order_date, amount) {
		let url = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_KONFIRMASI_BAYAR}&text=`
		let params = `Saya sudah melakukan pembayaran paket pengiriman smallpaket.\nOrder No: *${order_no}* \nOrder Date: *${order_date}*\nNominal: *${Helper.formatMoney(amount)}*\n\nSilahkan lampirkan bukti pembayaran Anda`;
	return url+encodeURIComponent(params);
	}

	static getDateFromInput(date){
		const newDate = new Date(date);
		const day = newDate.getDate()
		const month = newDate.getMonth()+1
		const year = newDate.getFullYear()
		return (
			(day < 10 ? `0${day}` : day) + "-" +
			(month < 10 ? `0${month}` : month) + "-"+
			year
		)
	}
}