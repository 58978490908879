import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { resetError } from "../reducers/error";
import userGrey from '../assets/userGrey.png';
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { Config } from "../libs/config";

export default function ImportFoto({showBorder=true,type=null,defaultImage=null,onChange=(e)=>{}}) {
  const error = useSelector((state)=>state.error)
  const dispatch = useDispatch()
  const fileRef = useRef();
  const [isLoading,setIsloading] = useState(false)
  const [currentItem,setCurrentItem] = useState(null)
  const onClickBrowse = (e) => {
    e.preventDefault();
    if(!isLoading) fileRef.current.click()
  }

  const onChangeImage = (e) => {
    setIsloading(true);
    const accessToken = localStorage.getItem("accessToken")
    const target = e.target.files[0];
    const formData = new FormData();
    formData.append('file',target,target.name)
    formData.append("title","Foto bayi")
    if(type == 'Profile') {
      formData.append('type',type)
      formData.append('title', 'Profile Picture');
      formData.append('description', 'Profile Picture');
    }
    axios.post(Config.env().BASE_URL+"/_/api/auth/files",formData,{
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '+accessToken,
        "Content-Type": 'multipart/form-data'
      }
    }).then(res=>{
      setCurrentItem(res.data.result)
      setIsloading(false)
    })
  }

  useEffect(()=>{
    onChange(currentItem)
  },[currentItem])
  
  return (
    <div className="h-100">
      <a href="#" onClick={(e)=>{onClickBrowse(e)}} className={"p-2 d-flex justify-content-center align-items-center"+(showBorder?' border':null)} style={{
          maxWidth: '150px',
          maxWidth: '150px',
          height: '150px',
        }}>
        <div >
          { !isLoading && !currentItem && !defaultImage && (
            <img src={userGrey} style={{
              width: "100%",
              height: '100%'
            }}  />
          )}
          { !isLoading && !currentItem && defaultImage && (
            <img src={defaultImage} style={{
              width: "100%",
              height: '100%'
            }}  />
          )}
          { !isLoading && currentItem && (
            <img src={currentItem?.file_url} style={{
              width: "100%",
              aspectRatio: '1'
            }} className="border rounded-circle" />
          )}
          {isLoading && <Spinner animation="border"></Spinner> }
        </div>
      </a>
      <input type={"file"} style={{
        display: 'none'
      }} ref={fileRef} onChange={(e)=>{onChangeImage(e)}} />
    </div>
  )
}