import React from "react";
import ReactLoading from 'react-loading';

export default function Loading({isShow=false}) {
  
  return (
    <>
      {isShow &&
        <div className="h-100 w-100 d-flex justify-content-center align-items-center flex-row">
          <ReactLoading type={"bubbles"} color={"#e22"} height={'20%'} width={'20%'} />    
        </div>
      }
    </>
  )
}