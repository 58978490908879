import React, { useEffect } from "react";
import Header from "../components/Header";
import { useGet } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useHistory } from "react-router-dom";
import { Session } from "../libs/session";


export default function Logout() {
  const history = useHistory();
  
  useEffect(()=>{
    Session.set("accessToken",null)
    history.push("/login")  
  },[])
  return (
    <>
      Logout
    </>
  )
}