import React from "react";
import { Carousel } from "react-bootstrap";

export default function Slider({items=[]}) {
  return (
    <>
      <Carousel>
        {items.map((v,i,a)=>{
          return (
            <Carousel.Item interval={1500} key={v.id}>
                <img
                  className="d-block w-100"
                  src={v.file.file_url}
                  alt={v.title}
                  style={{
                    height: 200,
                    weight: '100%'
                  }}
                />
                <Carousel.Caption>
                  {v?.tile && (
                    <h3>{v.title}</h3>
                  )}
                  
                  {v?.description && (
                    <p>{v.description}</p>
                  )}
                  
                </Carousel.Caption>
              </Carousel.Item>
            )
        })}
      </Carousel>
    </>
  )
}