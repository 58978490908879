import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useChatId, useGet } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useHistory } from "react-router-dom";
import { FaPlus, FaSearch } from "react-icons/fa";

const RowList = ({row}) => {
  const history = useHistory();
  const [{response},doSubmit] = useChatId();

  const getConversationId = async()=>{
    await doSubmit(row.id)
  }

  useEffect(()=>{
    if(!response) return ;
    // console.log(response.data.result.chat_id)
    if(response && response?.data?.result?.chat_id)
    history.push("/chat/"+response.data.result.chat_id)
  },[response])
  return (
    <a href="#" onClick={(e)=>{e.preventDefault();getConversationId()}} style={{
      textDecoration: 'none',
    }}>
      <div className="row bg-white p-2 m-2">
        <div className="col-sm-2">
          <img src={row.avatar} className={"rounded-circle"} style={{
            aspectRatio:'1/1',
            width: '100%'
          }} />
        </div>
        <div className="col-sm-10">
          <div className="text-bold" style={{
            color: "#333"
          }}>{row.name}</div>
          <div className="text-grey text-sm">{row?.role?.name}</div>
        </div>
      </div>
    </a>
  )
}

export default function ChatSearch() {
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/_/api/chat-users");
  const history = useHistory();
  const [category,setCategory] = useState("All");
  const [indexKey,setIndexKey] = useState(1);
  useEffect(()=>{
    // fetchData();
  },[])
  useEffect(()=>{
    setIndexKey(indexKey+1)
    fetchData({
      role_id: category
    })
  },[category])
  return (
    <div className="d-flex flex-column">
      <Header title={"Cari Teman Chatting"} showBackButton={true} />
      <ul className="nav bg-white justify-content-between">
        <li className={"nav-item "+(category==="All"?'bg-primary2':'')}>
          <a className="nav-link active text-dark" aria-current="page" href="#" onClick={(e)=>{e.preventDefault();setCategory("All")}}>
            Semua
          </a>
        </li>
        <li className={"nav-item "+(category==3?'bg-primary2':'')}>
          <a className="nav-link text-dark" href="#" onClick={(e)=>{e.preventDefault();setCategory(3)}}>Perawat</a>
        </li>
        <li className={"nav-item "+(category==6?'bg-primary2':'')}>
          <a className="nav-link text-dark" href="#" onClick={(e)=>{e.preventDefault();setCategory(6)}}>Kader</a>
        </li>
        <li className={"nav-item "+(category==4?'bg-primary2':'')}>
          <a className="nav-link text-dark" href="#" onClick={(e)=>{e.preventDefault();setCategory(4)}}>Ibu BBLR</a>
        </li>
      </ul>
      <div className="m-2" key={indexKey}>
        {response?.data?.result && (
          response.data.result.map((v,i,a)=>{
            return (
              <RowList row={v} />
            )
          })
        )}
      </div>
      {response?.data?.result?.friends?.length === 0 &&
        <div className="d-flex align-items-center justify-content-center">
          Belum ada chatting
        </div>
      }
    </div>
  )
}