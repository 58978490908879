import React, { useEffect } from "react";
import Header from "../components/Header";
import { useGet } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useHistory } from "react-router-dom";
import { Session } from "../libs/session";


export default function About() {
  
  return (
    <div className="d-flex flex-column">
      <Header title={"Tentang CHUBBY BBLR"} showBackButton={true} />
      <div className="m-2 pb-10" style={{
        paddingBottom: 60,
        backgroundColor: "#fff",
        padding: 10, 
        borderRadius: 10,
      }}>
       <p>
         Aplikasi Cara Ibu Merawat Bayi Berat Badan Lahir Rendah  
         (CHUBBY BBLR dibaca CABI BBLR) adalah aplikasi tentang cara perawatan bayi BBLR di rumah. 
         Aplikasi ini ditujukan untuk orangtua dan keluarga yang memiliki bayi BBLR, para kader kesehatan, 
         perawat di berbagai tatanan pelayanan kesehatan maupun masyarakat umum lainnya. 
         <br/><br/>
         Bagi orangtua yang memiliki bayi BBLR, aplikasi ini akan membantu mereka lebih memahami cara perawatan bayi BBLR, khususnya setelah bayi dibawa pulang ke rumah. Bagi kader kesehatan, aplikasi ini dapat membantu untuk memberikan edukasi dan memantau ibu dalam melakukan keberlanjutan perawatan BBLR di rumah maupun memantau kondisi Kesehatan bayi. Bagi perawat, aplikasi ini dapat membantu dalam memberikan edukasi tentang perawatan BBLR selain itu  dapat digunakan untuk memantau kader kesehatan dalam melakukan pemantauan pada keluarga yang memiliki bayi BBLR. <br/><br/>Aplikasi CHUBBY BBLR dilengkapi dengan kolom chatting atau obrolan, dimana ibu-ibu atau keluarga yang memiliki BBLR dapat saling berkomunikasi dengan anggota keluarga lainnya tentang cara perawatan BBLR. Obrolan bersifat terbuka, sehingga bisa dilakukan juga dengan kader kesehatan ataupun perawat. Obrolan juga bisa dilakukan antar kader kesehatan ataupun kader kesehatan dengan perawat kesehatan, demikian juga sebaliknya.<br/><br/>Aplikasi CHUBBY BBLR dilengkapi dengan kolom konsultasi, dimana para orangtua dapat berkonsultasi dengan kader kesehatan maupun perawat. Demikian juga kader Kesehatan dapat berkonsultasi dengan perawat Kesehatan <br/><br/>Aplikasi CHUBBY BBLR dilengkapi dengan beragam informasi seperti buku panduan, modul maupun buku saku tentang perawatan BBLR. Selain itu juga dilengkapi dengan video-video cara perawatan bayi yang didapatkan dari berbagai sumber. Kolom permainan game juga disediakan, untuk memfailitasi relaks para orangtua maupun kader Kesehatan di sela-sela kesibukan mengurus bayi ataupun kegiatan lainnya 
                      </p>
      </div>
    </div>
  )
}