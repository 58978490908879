import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useGet } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useParams } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ArtikelDetail() {
  let { id } = useParams();  
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/api/artikel/"+id);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(()=>{
    fetchData()
  },[])
  return (
    <div className="d-flex flex-column">
      <Header title={response?.data?.result?.title} showBackButton={true} />
      <div className="p-2 vh-100 w-100">
        {response?.data?.result?.file_content?.file_url && (
            <div className={"d-flex flex-column"}>
              <Document file={response?.data?.result?.file_content?.file_url} onLoadSuccess={onDocumentLoadSuccess}
                        style={{
                          width: 100
                        }}>
                <Page pageNumber={pageNumber} />
              </Document>
              {numPages != null &&
                  <div className={"mt-2 d-flex justify-content-between"}>
                    <button className={"btn btn-outline-dark"} disabled={pageNumber === 1} onClick={() => {
                      if (pageNumber > 1) {
                        setPageNumber(pageNumber - 1)
                      }
                    }}>
                      Sebelumnya
                    </button>
                    <button className={"btn btn-outline-dark"} disabled={pageNumber === numPages} onClick={() => {
                      if (pageNumber < numPages) {
                        setPageNumber(pageNumber + 1)
                        setPageNumber(pageNumber + 1)
                      }}
                    }>
                      Selanjutnya
                    </button>
                  </div>
              }
            </div>
        )}
      </div>
    </div>
  )
}