import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Config } from "../libs/config";
import { Session } from "../libs/session";
import { setError, resetError } from '../reducers/error'

export const useGet = (url,payload={}) => {
  const [response,setResponse] = useState(null)
  const [error,setErrorText] = useState(null)
  const [isLoading,setIsloading] = useState(false)
  const [newUrl, setNewUrl] = useState(url);
  const [newPayload,setNewPayload] = useState(payload);
  const dispatch = useDispatch();
  const fetchData = async (data=null) => {
    setIsloading(true);
    const token = Session.get("accessToken");
    let queryString;
    if(data) {
      queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    }
    
    await axios.get(newUrl+(data ? "?"+queryString : ''),{
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '+token
      }
    })
    .then(res=>setResponse(res))
    .catch(err=>{
      if(err.response && err.response.status){
        dispatch(setError({
          message:  err.message,
          code: err.response.status
        }))
      } else {
        dispatch(setError({
          message: "Unknown Error",
          code: 400
        }))
      }
      setErrorText(err)
    })
    setIsloading(false)
  }

  return [{response,error,isLoading},fetchData,setNewUrl,setNewPayload];
}

export const usePost = (url) => {
  const [response,setResponse] = useState(null)
  const [error,setErrorText] = useState(null)
  const [isLoading,setIsloading] = useState(false)
  const [newUrl, setNewUrl] = useState(url);
  const dispatch = useDispatch();

  // const dispatch = useDispatch();
  const fetchData = async (payload={},errorMessage=null) => {
    setIsloading(true);
    dispatch(resetError())
    const token = localStorage.getItem("accessToken");
    
    await axios.post(newUrl,payload,{
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '+token
      }
    })
    .then(res=>setResponse(res))
    .catch(err=>{
      setIsloading(false);
      if(err.response && err.response.status){
        dispatch(setError({
          message: errorMessage ? errorMessage : err.message,
          code: err.response.status
        }))
      } else {
        dispatch(setError({
          message: errorMessage ? errorMessage : "Unknown Error",
          code: 400
        }))
      }
      setErrorText(err)
    })
    setIsloading(false)
  }

  return [{response,error,isLoading},fetchData,setNewUrl];
}

export const useDelete = (url) => {
  const [responseDelete,setResponse] = useState(null)
  const [errorDelete,setErrorText] = useState(null)
  const [isLoadingDelete,setIsloading] = useState(false)
  const [newUrl, setNewUrlDelete] = useState(url);
  const dispatch = useDispatch();

  // const dispatch = useDispatch();
  const fetchDataDelete = async (id,payload={},errorMessage=null) => {
    setIsloading(true);
    dispatch(resetError())
    const token = localStorage.getItem("accessToken");
    
    await axios.delete(newUrl+"/"+id,{
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '+token
      },
      data: payload
    })
    .then(res=>setResponse(res))
    .catch(err=>{
      setIsloading(false);
      if(err.response && err.response.status){
        dispatch(setError({
          message: errorMessage ? errorMessage : err.message,
          code: err.response.status
        }))
      } else {
        dispatch(setError({
          message: errorMessage ? errorMessage : "Unknown Error",
          code: 400
        }))
      }
      setErrorText(err)
    })
    setIsloading(false)
  }

  return [{responseDelete,errorDelete,isLoadingDelete},fetchDataDelete,setNewUrlDelete];
}

export const useChatId = () => {

  const [response,setResponse] = useState(null)
  const doSubmit = async (user_id) => {
    const token = Session.get("accessToken");
    await axios.get(Config.env().BASE_URL+"/_/api/chat-detail-by-userid/"+user_id,{
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '+token
      }
    })
    .then(res=>setResponse(res))
    .catch(err=>{
      
    })
  }
  return [{response},doSubmit];
}

