import React from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { resetError } from "../reducers/error";

export default function AlertError() {
  const error = useSelector((state)=>state.error)
  const dispatch = useDispatch()
  const dismissError = ()=>{
    dispatch(resetError())
  }
  return (
    <div className="h-100">
      {error && error.errorMessage && (
        <Alert key={"danger"} variant={"danger"} onClose={() => dismissError()} dismissible style={{
          width: '100%'
        }}>
          [{error.errorCode}] {error.errorMessage}
        </Alert>
      )}
    </div>
  )
}