import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useGet } from '../hooks/fetch';

import { Config } from "../libs/config";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import Helper from "../libs/helper";
import DatePicker from 'react-datepicker';
import axios from "axios";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";


export default function Survey() {
  let { id } = useParams();  
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/_/api/jsons/"+id);
  const history = useHistory()
  const [title,setTitle] = useState("");
  const [currentQuestion,setCurrentQuestion] = useState(null)
  const [currentIndex,setCurrentIndex] = useState(0)
  const [currentAnswer,setCurrentAnswer] = useState(null);
  const [isHasNext,setIsHasNext] = useState(false)
  const [isFinish,setIsFinish] = useState(false)
  const [submitData,setSubmitData] = useState([]);

  useEffect(()=>{
    fetchData()
    if(id == 1) setTitle("Pengkajian Ibu")
    else if(id == 2) setTitle("Pengkajian Perawat")
    else if(id == 3) setTitle("Pengkajian Kader")
    else setTitle("Pengkajian Anak")
  },[])

  useEffect(()=>{
    if(!response) return ;
    setCurrentQuestion(response.data.result.json[0])
    setCurrentIndex(0)
    if((currentIndex+1) != response.data.result.json.length){
      setIsHasNext(true)
    } else {
      setIsHasNext(false)
      setIsFinish(true)
    }
  },[response])

  useEffect(()=>{
    if(response){
      if((currentIndex+1) != response.data.result.json.length){
        setIsHasNext(true)
        let _current = response.data.result.json[currentIndex]; 
        if(_current.show_if){
          let _noCheck = _current.show_if.no;
          let _valueIf = _current.show_if.value; 
          let _valueCheck = response.data.result.json[_noCheck-1];
          if(_valueIf !== _valueCheck.value) {
            setCurrentQuestion(response.data.result.json[currentIndex+1])
            setCurrentIndex(currentIndex+1)
            setTimeout(()=>{

            },[1000])
          }
        }
      } else {
        setIsHasNext(false)
        setIsFinish(true)
      }
    }
  },[currentIndex])

  const onNext = () =>{
    if(currentAnswer === null) alert("Wajib Diisi")
    else if(typeof currentAnswer === "object" && currentAnswer.length === 0) alert("Wajib Diisi") 
    else {
      if((currentIndex+1) != response.data.result.json.length){
        let tmp = currentQuestion;
        tmp.value = currentAnswer;
        let arr = submitData;
        arr.push(tmp)
        setSubmitData(arr)
        setCurrentQuestion(response.data.result.json[currentIndex+1])
        setCurrentIndex(currentIndex+1)
        setCurrentAnswer(null)
      } else {
        
      } 
    }
  }

  const getNextIndex = (currentIndex) => {
    let tmpIndex = currentIndex;
    if(tmpIndex == response.data.result.json.length) return null;

  }

  const onSubmit = async() =>{
    if(currentAnswer === null) alert("Wajib Diisi")
    else {
      let tmp = currentQuestion;
      tmp.value = currentAnswer;
      let arr = submitData;
      arr.push(tmp)
      console.log(arr)
      const token = localStorage.getItem("accessToken");
    
      await axios.post(Config.env().BASE_URL+"/api/surveys",{
        type: 'Pengkajian',
        data: arr
      },{
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '+token
        }
      })
      .then(res=>{
        toast.success('Berhasil menambah data', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.goBack()
      })
      .catch(err=>{
        
      })
    }
  }

  const setCheckboxChange = (e,value)=>{
    let tmp = currentAnswer;
    if(tmp === null){
      tmp = [];
    }
    if(e.target.checked && !tmp.includes(value)){
      tmp.push(value)
    } else {
      tmp = tmp.filter((e)=>e != value)
    }

    setCurrentAnswer(tmp)
  }

  return (
    <div className="d-flex flex-column">
      <Header title={title} showBackButton={true} />
      <div className="d-flex p-1 flex-row justify-content-end" style={{
        fontWeight: 'bold',
        fontSize: '.8rem'
      }}>
        {currentIndex+1}/{response?response.data.result.json.length:0}
      </div>
      <div className="p-2 vh-100 w-100" key={currentIndex}>
        <Loading isShow={isLoading} />
        {currentQuestion && !isLoading && 
          <div className="d-flex flex-column">
            <div className="bg-white m-2 p-2 d-flex flex-row align-items-center justify-content-center" style={{
              minHeight: '200px',
              fontWeight: 'bold',
              fontSize: '1rem'
            }}>
              {currentQuestion.question}
            </div>
            <div className="bg-white m-2 p-2 d-flex flex-column align-items-center justify-content-center">
            
            {
              currentQuestion.type === "text" &&
              <input type={"text"} placeholder="Jawab Disini" onChange={(e)=>setCurrentAnswer(e.target.value)} className="form-control" />
            }
            {
              currentQuestion.type === "checkbox" &&
              <div className="w-100">
                {
                  currentQuestion.options.map((v,i,a)=>{
                    return (
                      <div key={i}>
                        <input type={"checkbox"} placeholder="Jawab Disini" onChange={(e)=>{
                          setCheckboxChange(e,v.value)
                        }} className="" /> {v.label}
                      </div>
                    )
                  })
                }
                
              </div>
            }
            {
              currentQuestion.type === "date" &&
              <DatePicker  
              peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
              onChange={(e)=>setCurrentAnswer(Helper.getDateFromInput(e))} dateFormat="yyyy-mm-dd" value={currentAnswer} style={{
                width:'100% !important'
              }}
              className="form-control"
              />
            }
            {
              currentQuestion.type === "textarea" &&
              <textarea placeholder="Jawab Disini" onChange={(e)=>setCurrentAnswer(e.target.value)} className="form-control"></textarea>
            }
            {
              currentQuestion.type === "number" &&
              <input type={"number"} placeholder="Jawab Disini" onChange={(e)=>setCurrentAnswer(e.target.value)} className="form-control" />
            }
            {
              currentQuestion.type === "select" &&
              <select className="form-control" onChange={(e)=>setCurrentAnswer(e.target.value)}>
                <option>-- Silahkan Pilih Jawaban --</option>
                {currentQuestion.options.map((v,i,a)=>{
                  return (
                    <option value={v.label} key={i}>{v.value}</option>
                  )
                })}
              </select>
            }
            
            {isHasNext &&
              <button className="btn btn-primary block w-100 mt-2" type="button" onClick={()=>onNext()}>
                Selanjutnya
              </button>
            }
            {isFinish &&
              <button className="btn btn-primary block w-100 mt-2" type="button" onClick={()=>onSubmit()}>
                Submit
              </button>
            }
            </div>
            
          </div>
        
        }
        
             </div>
    </div>
  )
}