import React, { useEffect } from "react";
import Header from "../components/Header";
import { useGet } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useHistory } from "react-router-dom";

const RowList = ({_id,icon,title,description}) => {
  const history = useHistory();
  return (
    <a href="#" style={{
        textDecoration: 'none',
      }} 
      className="d-flex flex-row mb-2 border-bottom bg-white"
      onClick={(e)=>{e.preventDefault();history.push("/artikel-detail/"+_id)}}
    >
      <img src={icon} width={100} height={100} />
      <div className="ps-2 pt-1">
        <div style={{
          maxLines: 1,
          maxHeight: 70,
          overflow: 'hidden',
          fontSize: 16,
          fontWeight: 'bold',
          color: '#333'
        }}>{title}</div>
        <div style={{
          maxLines: 1,
          maxHeight: 70,
          overflow: 'hidden',
          fontSize: 11,
          color: 'grey'
        }}>{description.replace(/<[^>]*>?/gm, '')}</div>
      </div>
    </a>
  )
}

export default function Artikel() {
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/api/artikel");
  
  useEffect(()=>{
    fetchData();
  },[])
  return (
    <div className="d-flex flex-column">
      <Header title={"Edukasi Perawatan BBLR"} />
      <div className="m-2">
        {response?.data?.result?.data && (
          response.data.result.data.map((v,i,a)=>{
            return (
              <RowList _id={v.id} icon={v.thumbnail_file.file_url} title={v.title} description={v.content_clean} />
            )
          })
        )}
       
      </div>
      
        <BottomNav active={1} />
    </div>
  )
}