import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useGet, usePost } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import DatePicker from 'react-datepicker';
import { FaMale } from "react-icons/fa";
import ButtonLoading from "../components/ButtonLoading";
import ImportFoto from "../components/ImportFoto";
import Helper from "../libs/helper";
import { useHistory } from "react-router-dom";

export default function TimelineAdd() {
  const history = useHistory()
  const [name,setName] = useState(null)
  const [dob, setDob] = useState()
  const [gender,setGender] = useState('Laki-Laki')
  const [fotoId,setFotoId] = useState(null);
  const [{response,error,isLoading},fetchData,setNewUrl] = usePost(Config.env().BASE_URL+"/api/timeline")
  const onSubmit = (e)=>{
    e.preventDefault()
    // alert("submit")
    fetchData({
      description: name,
      images: [fotoId]
    })
    history.goBack()
  }
  return (
    <div className="d-flex flex-column">
      <Header title={"Buat Postingan"} showBackButton={true} />
      <div className="m-2 vh-100">
        <div className="p-3 bg-white border border-rounded rounded">
          <form onSubmit={(e)=>onSubmit(e)}>
              <div className="mb-3">
                <label className="label ">Keterangan</label>
                <textarea className="form-control" type="text" name="email" onChange={(e)=>{setName(e.target.value)}} required></textarea>
              </div>
              <div className="mb-3">
                <label className="label ">Lampiran Gambar</label>
                <ImportFoto onChange={(e)=>{setFotoId(e?.id)}} />
              </div>
              <div className="mb-3">
                <ButtonLoading title={"Submit"} isLoading={isLoading} className="w-100" />
              </div> 
          </form>
        </div>
      </div>
    </div>
  )
}