import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AlertError from "../components/AlertError";
import ButtonLoading from "../components/ButtonLoading";
import { usePost } from "../hooks/fetch";
import { Config } from "../libs/config";
import { Session } from "../libs/session";
import kanguru from "../assets/kangaroo.png";
export default function Login() {
  const [{response,error,isLoading},fetchData,setNewUrl] = usePost(Config.env().BASE_URL+"/oauth/token")
  const [email,setEmail] = useState(null)
  const [password,setPassword] = useState(null);
  const onSubmit = async(e) => {
    e.preventDefault();
    
    fetchData({
      username: email,
      password: password,
      client_id: Config.env().APP_ID,
      client_secret: Config.env().APP_SECRET,
      grant_type: 'password',
      scope: null
    },"Akun Tidak Ditemukan")
  }

  useEffect(()=>{
    if(!response) return ;
    Session.set("accessToken",response.data.access_token)
    window.location.href = "/";
  },[response])
  
  return (
    <div className="App w-100">
      <section style={{
        paddingTop: '20%'
      }}>
          <div className="container">
              <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-12">
                      <div className="card mb-5">
                          <div className="card-body d-flex flex-column align-items-center" style={{
                            // backgroundColor: "#efe2e7"
                          }}>
                            <div className="row">
                                <div className="col-12">
                                  <img src={kanguru} width={100} className={"border rounded rounded-circle p-2"} />
                                    <h2>Selamat Datang</h2>
                                    <p className="w-lg-50">Di Aplikasi Chubby BBLR </p>
                                </div>
                            </div>
                            <AlertError />
                              <form className="text-start w-100" method="post" onSubmit={(e)=>onSubmit(e)}>
                                  <div className="mb-3">
                                    <label className="label ">Email / Telepon</label>
                                    <input className="form-control" type="email" name="email" placeholder="Email" onChange={(e)=>setEmail(e.currentTarget.value)} required />
                                  </div>
                                  <div className="mb-3">
                                    <label className="label ">Password</label>
                                    <input className="form-control" type="password" name="password" placeholder="Password" onChange={(e)=>setPassword(e.currentTarget.value)} required />
                                  </div>
                                  <div className="mb-3">
                                    <ButtonLoading title={"Login"} className="d-block w-100" isLoading={isLoading}/>
                                  </div>
                                  <p className="text-center text-muted">atau</p>
                                  <a href="/register" className="btn btn-danger d-block w-100" type="button">Pendaftaran Akun</a>
                                  <p className="text-muted mt-2 text-xs" style={{
                                    'textAlign': 'right',
                                    'fontSize': 12
                                  }}>Lupa Password?</p>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </div>
  )
}