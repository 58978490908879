import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGet } from "../hooks/fetch";
import { Config } from "../libs/config";
import { setUser } from '../reducers/auth';
import userIcon from '../assets/user.png';
import { FaBell, FaArrowLeft } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
export default function Header({ title, type = null, showBackButton, rightChild }) {
    const dispatch = useDispatch();
    const [{ response, error, isLoading }, fetchData, setNewUrl, setNewPayload] = useGet(Config.env().BASE_URL + "/_/api/auth/profile")
    const auth = useSelector((state) => state.auth)
    const history = useHistory();
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(()=>{
        if(!error) return ;
        history.push("login")
    },[error])

    useEffect(() => {
        if (!response) return;
        dispatch(setUser(response.data.result))
    }, [response])

    return ( 
        <div style = {{
            minHeight: '50px',
            backgroundColor: '#FFBC95'
        }} className="align-items-center py-2 pe-3 h-100"> 
        { type === 'user' && response && (
            <div className="d-flex justify-content-between align-items-center h-100"> { /* <img src={response.data.result.avatar} width={40} height={40} alt="User"/> */ } 
                <div className = "d-flex px-2" >
                    <img src = {  response?.data?.result?.avatar  } width = { 40 } height = { 40 } alt = "User" / >
                    <div className="d-flex flex-column px-2">
                        <div style = {{
                                fontWeight: 'bold',
                                fontSize: 17
                            }}> { response.data.result.name } 
                        </div> 
                        <div style = {{
                                fontSize: 14
                        }}> 
                            { response.data.result.email } 
                        </div> 
                    </div>
                </div> 
                <div className="">
                    <a href="#" onClick={(e)=>{e.preventDefault();alert("Notif")}}>
                        <FaBell size={20} color={"white"} />
                    </a>
                </div>
            </div>
        )} 
        {!type && ( 
            <div style = {{
                    color: "#333",
                    fontSize: 19,
                    fontWeight: 'bold',
                    maxHeight: 20,
                    overflow: 'hidden'
                }} className={"px-2 d-flex align-items-center flex-row justify-content-between"}> 
                <div className="d-flex">
                    {showBackButton && (
                        <a href="#" onClick={(e)=>{e.preventDefault();history.goBack()}} className={"me-3"}>
                        <FaArrowLeft size={20} color={"white"} />
                        </a>
                    )}
                    
                    <div className="text-white">
                        { title }
                    </div> 
                </div>
                {rightChild}
            </div>
        )}
        </div>
    )
}