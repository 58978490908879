import React from "react";
import { Button, Spinner } from "react-bootstrap";

export default function ButtonLoading({title,className,isLoading=false,onClick=(e)=>{}}) {
  return (
    <>
      <Button variant="primary" className={className} disabled={isLoading} onClick={(e)=>{onClick(e)}} type="submit">
        {!isLoading && title}
        {isLoading && (
          <>
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>&nbsp;
            Loading ...
          </>
        )}
      </Button>
    </>
  )
}