import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useGet, usePost } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import DatePicker from 'react-datepicker';
import { FaCalendar, FaFemale, FaHistory, FaMale, FaPlus } from "react-icons/fa";
import ButtonLoading from "../components/ButtonLoading";
import ImportFoto from "../components/ImportFoto";
import Helper from "../libs/helper";
import bayiIcon from "../assets/mybaby.png";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import dataBeratBadan from '../config/berat_badan.json';
import dataTinggiBadan from '../config/tinggi_badan.json';

const RowHistory = ({item}) => {
    return (
        <div className="bg-white border border-rounded rounded mb-2 p-3">
            <div className="row">
                <div className="col-4 text-bold">Bulan Ke</div>
                <div className="col-6">{item?.week}</div>
            </div>
            <div className="row">
                <div className="col-4 text-bold">Berat Badan</div>
                <div className="col-6">{item?.weight} kg</div>
            </div>
            <div className="row">
                <div className="col-4 text-bold">Tinggi Badan</div>
                <div className="col-6">{item?.height} cm</div>
            </div>
            <div className="row">
                <div className="col-4 text-bold">Diisi Pada</div>
                <div className="col-6">{item?.created_at_label} WIB</div>
            </div>
        </div>
    )
}

export default function BayikuDetail() {
    let {id} = useParams();
    const history = useHistory()
    const [item, setItem] = useState(null)
    const [chartKey, setChartKey] = useState(1)
    const [chartTinggiBadan, setChartTinggiBadan] = useState({
        chart: {
            zoomType: 'x'
        },
        title: {
            text: "Perkembangan Panjang Badan"
        },
        subtitle: {
            text: "Dalam Centimeter (cm)"
        },
        yAxis: {
            title: {
                text: 'Panjang Badan (cm)'
            }
        },
        xAxis: {
            categories: []
        },
        series: []
    })
    const [chartBeratBadan, setChartBeratBadan] = useState({
        chart: {
            zoomType: 'x'
        },
        title: {
            text: "Perkembangan Berat Badan"
        },
        subtitle: {
            text: "Dalam Centimeter (kg)"
        },
        yAxis: {
            title: {
                text: 'Berat Badan (kg)'
            }
        },
        xAxis: {
            categories: []
        },
        series: []
    })

    useEffect(() => {
        axios.get(Config.env().BASE_URL + "/api/babies/" + id, {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem("accessToken")
            }
        }).then((res) => {
            setItem(res.data.result)
            let panjangBadan = [];
            let beratBadan = [];
            res.data.result.records.map((v, i, a) => {
                beratBadan.push(v.weight);
                panjangBadan.push(v.height)
            })
            let configPanjang = chartTinggiBadan;
            let configBerat = chartBeratBadan;
            configPanjang.series = [
                {name: "Bayiku", data: panjangBadan.reverse()},
                {name: 'Rata-Rata', data: dataTinggiBadan.laki_laki.median}
            ]
            configBerat.series = [
                {name: "Bayiku", data: beratBadan.reverse()},
                {name: 'Rata-Rata', data: dataBeratBadan.laki_laki.median}
            ]
          for(let i=1;i<=60;i++){
            configBerat.xAxis.categories.push(i);
            configPanjang.xAxis.categories.push(i);
          }
            setChartTinggiBadan(configPanjang)
            setChartBeratBadan(configBerat)
            setChartKey(chartKey + 1)
        })
    }, [])


    return (
        <div className="d-flex flex-column">
            <Header title={"Detail Bayiku"} showBackButton={true}/>
            <div className="d-flex bg-white m-2 p-3 border border-rounded rounded">
                <div>
                    <img src={item && item.photo ? item.photo.file_url : bayiIcon} width={70} height={70} className="border rounded-circle"/>
                </div>
                <div className="ps-3 d-flex flex-column">
                    <div className="text-bold">{item?.name}</div>
                    <div>
                        {item?.gender === 'Laki-Laki' && (
                            <small className="text-sm text-grey"><FaMale/>{item?.gender}</small>)}
                        {item?.gender !== 'Laki-Laki' && (
                            <small className="text-sm text-grey"><FaFemale/>{item?.gender}</small>)}
                        <small className="text-sm text-grey ms-2">
                            <FaCalendar/> {item?.dob_label} ({item?.umur})
                        </small>
                    </div>
                    <div>
                        <small className="text-sm text-grey">
                            <FaHistory className="me-1"/>Bulan Ke: {item?.pekan_input_terakhir}</small>
                    </div>
                </div>
            </div>
            <div className="m-2">
                <HighchartsReact
                    key={chartKey}
                    highcharts={Highcharts}
                    options={chartBeratBadan}
                />
            </div>
            <div className="m-2">
                <HighchartsReact
                    key={chartKey}
                    highcharts={Highcharts}
                    options={chartTinggiBadan}
                />
            </div>
            <div className="m-2 vh-100">
                <div className="">
                    {item && (
                        item.records.map((v, i, a) => {
                            return (
                                <RowHistory item={v} key={v.id}/>
                            )
                        })
                    )}

                    <div className="d-flex flex-column p-2 justify-content-center align-items-center">
                        {item && item.records.length == 0 && (<i>Belum ada riwayat</i>)}
                        <Button variant="primary" size="sm" className="my-3"
                                onClick={() => history.push("/bayiku-add-riwayat/" + item.id)}>
                            <FaPlus/> Tambah Riwayat
                        </Button>
                    </div>


                </div>
            </div>
        </div>
    )
}