import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useGet, usePost } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import DatePicker from 'react-datepicker';
import { FaMale } from "react-icons/fa";
import ButtonLoading from "../components/ButtonLoading";
import ImportFoto from "../components/ImportFoto";
import Helper from "../libs/helper";
import { useHistory } from "react-router-dom";

export default function AkunUbahPassword() {
  const history = useHistory()
  const [currentPassword,setCurrentPassword] = useState(null)
  const [newPassword, setNewPassword] = useState(null)
  const [newPasswordConfirm,setNewPasswordConfirm] = useState(null)
  const [fotoId,setFotoId] = useState(null);
  const [{response,error,isLoading},fetchData,setNewUrl] = usePost(Config.env().BASE_URL+"/api/babies")
  const onSubmit = (e)=>{
    e.preventDefault()
    // alert("submit")
    
    history.goBack()
  }
  return (
    <div className="d-flex flex-column">
      <Header title={"Ubah Password"} showBackButton={true} />
      <div className="m-2 vh-100">
        <div className="p-3 bg-white border border-rounded rounded">
          <form onSubmit={(e)=>onSubmit(e)}>
              <div className="mb-3">
                <label className="label ">Password Saat Ini</label>
                <input className="form-control" type="password" name="email" onChange={(e)=>{setCurrentPassword(e.target.value)}} required />
              </div>
              <div className="mb-3">
                <label className="label ">Password Baru</label>
                <input className="form-control" type="password" onChange={(e)=>{setNewPassword(e.target.value)}} required />
              </div> 
              <div className="mb-3">
                <label className="label ">Konfirmasi Password Baru</label>
                <input className="form-control" type="password" onChange={(e)=>{setNewPasswordConfirm(e.target.value)}} required />
              </div> 
              <div className="mb-3">
                <ButtonLoading title={"Submit"} isLoading={isLoading} className="w-100" />
              </div> 
          </form>
        </div>
      </div>
    </div>
  )
}