import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useDelete, useGet } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useHistory } from "react-router-dom";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const RowList = ({row,onDelete=(item)=>{}}) => {
  const history = useHistory();
  const [{ response, error, isLoading }, fetchData, setNewUrl, setNewPayload] = useGet(Config.env().BASE_URL + "/_/api/auth/profile")
    useEffect(()=>{
        fetchData()
    },[])
  return (
    <div className="w-100 bg-white d-flex flex-column mb-2">
      <img src={row.images[0]?.image?.file_url} style={{
        width: "100%",
        maxHeight: 300,
      }} />
      <div className="p-2 border d-flex flex-row justify-content-between">
          <div className={"d-flex flex-column"}>
              <div className="d-flex flex-column">
                  <div className="text-bold text-sm">{row?.created_by?.name}:</div>
                  <div className="text-sm">
                      {row?.description}
                  </div>
              </div>
              <div className="text-grey text-sm mt-2">{row?.created_at_label}</div>
          </div>
          {response && (response.data.result.id == row.created_by.id) && (
              <div className={""}>
                  <div className="text-grey text-sm mt-2">
                      <a href={"#"} onClick={(e)=>{e.preventDefault();onDelete(row.id)}}>
                        <FaTrash color={"red"} />
                      </a>
                  </div>
              </div>
          )}


      </div>

    </div>
  )
}

export default function Timeline() {
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/api/timeline");
    const [{responseDelete,errorDelete,isLoadingDelete},fetchDataDelete,setNewUrlDelete] = useDelete(Config.env().BASE_URL+"/api/timeline");
  const history = useHistory();
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const [itemSelected,setItemSelected] = useState(null)
  useEffect(()=>{
    fetchData();
  },[])

    const onDelete = (item)=>{
        setItemSelected(item);
        setShowConfirmDelete(true)
    }
    const onDeleteConfirmed = () => {
        setShowConfirmDelete(false)
        fetchDataDelete(itemSelected,{
            ids: itemSelected+","
        })
    }
    useEffect(()=>{
        if(!responseDelete) return ;
        toast.success('Berhasil menghapus data', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        fetchData();
    },[responseDelete])
  return (
    <div className="d-flex flex-column">
      <Header title={"Komunitas"} rightChild={
        <a href="#" onClick={(e)=>{e.preventDefault();history.push("timeline-add")}} className="text-dark text-sm a-free d-flex align-items-center justify-content-center text-center text-middle text-white">
          <FaPlus />
          Tambah
        </a>
      } />
      <div className="m-2">
        {response?.data?.result?.data && (
          response.data.result.data.map((v,i,a)=>{
            return (
              <RowList row={v} onDelete={(e)=>onDelete(e)} />
            )
          })
        )}
       
      </div>
        <Modal show={showConfirmDelete} onHide={()=>setShowConfirmDelete(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Konfirmasi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Anda yakin ingin menghapus postingan ini?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setShowConfirmDelete(false)}>
                    Batal
                </Button>
                <Button variant="primary" onClick={()=>onDeleteConfirmed()}>
                    Ya
                </Button>
            </Modal.Footer>
        </Modal>
        <BottomNav active={1} />
    </div>
  )
}