import React, { useEffect } from "react";
import Header from "../components/Header";
import { useGet } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useHistory } from "react-router-dom";

const RowList = ({link,icon,title}) => {
  const history = useHistory();
  return (
    <a href={link} target={"_blank"} style={{
        textDecoration: 'none',
      }} 
      className="d-flex flex-column mb-2 border-bottom bg-white"
    >
      <img src={icon} width={"100%"} height={200} />
      <div className="ps-2 pt-1">
        <div style={{
          maxLines: 1,
          maxHeight: 70,
          overflow: 'hidden',
          fontSize: 16,
          fontWeight: 'bold',
          color: '#333'
        }}>{title}</div>
        
      </div>
    </a>
  )
}

export default function Game() {
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/api/app?_as[type.name][value]=Komunitas&_as[type.name][operator]==");
  
  useEffect(()=>{
    fetchData();
  },[])
  return (
    <div className="d-flex flex-column">
      <Header title={"Daftar Game Pilihan"} showBackButton={true} />
      <div className="m-2 pb-10" style={{
        paddingBottom: 60
      }}>
        {response?.data?.result?.data && (
          response.data.result.data.map((v,i,a)=>{
            return (
              <RowList _id={v.id} icon={v?.file?.file_url} title={v.name} link={v.link} />
            )
          })
        )}
       
      </div>
    </div>
  )
}