import React, { useEffect } from "react";
import Header from "../components/Header";
import Slider from "../components/Slider";
import { useGet } from '../hooks/fetch';
import { Config } from "../libs/config";
import mybaby from '../assets/mybaby.png';
import medis from '../assets/medis.png';
import game from '../assets/game.png';
import video from '../assets/video.png';
import mom from '../assets/mom.png';
import chat from '../assets/chat.png';
import BottomNav from "../components/BottomNav";
import { useHistory } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

const Banner = () => {
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/api/banner");
  useEffect(()=>{
    fetchData();
  },[])
  
  return (
    <>
      {response?.data?.result?.data && (
        <Slider items={response.data.result.data} />
      )}
    </>
  )
}

const RowMenu = ({title,icon,path}) => {
  const history = useHistory();
  return (
    <a href="#" style={{
      textDecoration: 'none',
      color: "#333"
    }} onClick={(e)=>{e.preventDefault();history.push(path)}}>
      <div className="d-flex flex-column align-items-center me-4">
        <img src={icon} alt={title} width={70} height={70} />
        <div style={{
          fontSize: 13,
          marginTop: 5
        }}>{title}</div>
      </div>
    </a>
  )
}

const PengkajianRow = ({title,to}) => {
  const history = useHistory();
  return (
    <a href="#" className="border rounded-xl m-2 p-2 bg-white d-flex flex-row justify-content-between align-items-center text-dark" style={{
      textDecoration: 'none'
    }} 
      onClick={(e)=>{e.preventDefault();history.push(to)}}
    >
      <div>{title}</div>
      <div>
        <FaChevronRight />
      </div>
    </a>
  )
}

export default function Dashboard({navigation}) {
  
  return (
    <div className="d-flex flex-column">
      <Header type="user" />
      <div className="p-2">
        <Banner />
        <div className="mt-2 d-flex flex-column" style={{
          backgroundColor: "white",
          padding: 10,
          borderRadius: 10
        }}>
          <div style={{fontSize: 14,fontWeight: 'bold'}}>Selamat datang di aplikasi CHUBBY BBLR</div>
          <div style={{
            fontSize: 12,
            color: 'grey'
          }}>
            Diaplikasi ini, Anda dapat berinteraksi dengan Ibu Menyusui, Kader Kesehatan dan Perawat Kesehatan. Anda juga dapat melihat mengisi dan melihat perkembangan anak Anda.
          </div>
        </div>
      </div>
      <div className="mt-2 d-flex flex-row align-items-start w-100 overflow-auto" style={{
          backgroundColor: 'white',
          padding: 5,
          scrollBehavior: 'auto'
        }}>
          <RowMenu title={"Bayiku"} icon={mybaby} path={"bayiku"} />
          <RowMenu title={"Perawatan"} icon={medis} path={"artikel"} />
          <RowMenu title={"Chatting"} icon={chat} path={"chat-friend-list"} />
          <RowMenu title={"Komunitas"} icon={mom} path={"timeline"} />
          <RowMenu title={"Video"} icon={video} path={"video"} />
          <RowMenu title={"Game"} icon={game} path={"game"} />
      </div>          
      <div className="mt-2 mb-5 pb-5">
        <PengkajianRow title={"Pengkajian Bayi"} to="/survey/4" />
        <PengkajianRow title={"Pengkajian Ibu"} to="/survey/1" />
        <PengkajianRow title={"Pengkajian Perawat"} to="/survey/2" />
        <PengkajianRow title={"Pengkajian Kader"} to="/survey/3" />
      </div>

        <BottomNav />
    </div>
  )
}