import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom"
import { isBrowser } from 'react-device-detect';
import Helper from './libs/helper';
import Login from './pages/Login';
import Register from './pages/Register';
import store from './store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import Dashboard from './pages/Dashboard';
import Artikel from './pages/Artikel';
import ArtikelDetail from './pages/ArtikelDetail';
import Video from './pages/Video';
import VideoDetail from './pages/VideoDetail';
import Game from './pages/Game';
import Akun from './pages/Akun';
import Logout from './pages/Logout';
import About from './pages/About';
import bgImage from './assets/background.png';
import Bayiku from './pages/Bayiku';
import BayikuAdd from './pages/BayikuAdd';
import BayikuAddRiwayat from './pages/BayikuAddRiwayat';
import BayikuDetail from './pages/BayikuDetail';
import AkunUbahPassword from './pages/AkunUbahPassword';
import Timeline from './pages/Timeline';
import TimelineAdd from './pages/TimelineAdd';
import ChatFriendList from './pages/ChatFriendList';
import ChatSearch from './pages/ChatSearch';
import Chat from './pages/Chat';
import Survey from './pages/Survey';

function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => {

		return (
			<Route
				{...rest}
				render={props =>
					Helper.guard() ? (
						<Component {...props} />
					) : (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: props.location }
							}}
						/>
					)
				}
			/>
		);
	}

  return (
		<Provider store={store}>
			<Router basename={"/"}>
				<div className='d-flex vh-100' style={{
					maxWidth: isBrowser ? '480px' : '100%',
					height: '100%',
					margin: 'auto',
					backgroundImage: `url(${bgImage})`
				}}>
					<div className='w-100'>
						<ToastContainer />
						<Switch>
							<PrivateRoute path={`/dashboard`} component={Dashboard} />
							<PrivateRoute path={`/artikel`} component={Artikel} />
							<PrivateRoute path={`/artikel-detail/:id`} component={ArtikelDetail} />
							<PrivateRoute path={`/video`} component={Video} />
							<PrivateRoute path={`/video-detail/:id`} component={VideoDetail} />
							<PrivateRoute path={`/game`} component={Game} />
							<PrivateRoute path={`/akun`} component={Akun} />
							<PrivateRoute path={`/akun-ubah-password`} component={AkunUbahPassword} />
							<PrivateRoute path={`/about`} component={About} />
							<PrivateRoute path={`/logout`} component={Logout} />
							<PrivateRoute path={`/bayiku`} component={Bayiku} />
							<PrivateRoute path={`/bayiku-add`} component={BayikuAdd} />
							<PrivateRoute path={`/bayiku-add-riwayat/:id`} component={BayikuAddRiwayat} />
							<PrivateRoute path={`/bayiku-detail/:id`} component={BayikuDetail} />
							<PrivateRoute path={`/timeline`} component={Timeline} />
							<PrivateRoute path={`/timeline-add`} component={TimelineAdd} />
							<PrivateRoute path={`/chat-friend-list`} component={ChatFriendList} />	
							<PrivateRoute path={`/chat-search`} component={ChatSearch} />	
							<PrivateRoute path={`/chat/:id`} component={Chat} />
							<PrivateRoute path={`/survey/:id`} component={Survey} />
							<Route path={`/login`}>
								<Login />
								
							</Route> 
							<Route path={`/register`}>
								<Register />
								
							</Route> 
							<Route path={`/`}>
								<Dashboard />
							</Route> 
						</Switch>
					</div>
				</div>
			</Router>
		</Provider>
  );
}

export default App;
