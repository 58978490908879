import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useGet, usePost } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import DatePicker from 'react-datepicker';
import { FaMale } from "react-icons/fa";
import ButtonLoading from "../components/ButtonLoading";
import ImportFoto from "../components/ImportFoto";
import Helper from "../libs/helper";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function BayikuAdd() {
  const history = useHistory()
  const [name,setName] = useState(null)
  const [dob, setDob] = useState()
  const [gender,setGender] = useState('Laki-Laki')
  const [fotoId,setFotoId] = useState(null);
  const [{response,error,isLoading},fetchData,setNewUrl] = usePost(Config.env().BASE_URL+"/api/babies")
  const onSubmit = (e)=>{
    e.preventDefault()
    // alert("submit")
    fetchData({
      name: name,
      dob: dob,
      gender: gender,
      photo_id: fotoId
    })

  }
  useEffect(()=>{
      if(!response) return ;
      toast.success('Berhasil menambah data', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
      history.goBack()
  },[response])
  return (
    <div className="d-flex flex-column">
      <Header title={"Tambah Data Bayi"} showBackButton={true} />
      <div className="m-2 vh-100">
        <div className="p-3 bg-white border border-rounded rounded">
          <form onSubmit={(e)=>onSubmit(e)}>
              <div className="mb-3">
                <label className="label ">Nama Lengkap</label>
                <input className="form-control" type="text" name="email" onChange={(e)=>{setName(e.target.value)}} required />
              </div>
              <div className="mb-3">
                <label className="label ">Tanggal Lahir</label>
                <DatePicker onChange={(e)=>setDob(Helper.getDateFromInput(e))} dateFormat="yyyy-mm-dd" value={dob} />
              </div> 
              <div className="mb-3">
                <label className="label ">Jenis Kelamin</label>
                <select className="form-control" onChange={(e)=>setGender(e.target.value)}>
                  <option value={"Laki-Laki"}>Laki-Laki</option>
                  <option value={"Perempuan"}>Perempuan</option>
                </select>
              </div> 
              <div className="mb-3">
                <label className="label ">Foto</label>
                <ImportFoto onChange={(e)=>{setFotoId(e?.id)}} />
              </div>
              <div className="mb-3">
                <ButtonLoading title={"Submit"} isLoading={isLoading} className="w-100" />
              </div> 
          </form>
        </div>
      </div>
    </div>
  )
}