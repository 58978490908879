import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import userIcon from "../assets/user.png";
import { useSelector } from "react-redux";
import { FaChevronRight, FaKey, FaPowerOff, FaQuestionCircle, FaUser } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ImportFoto from "../components/ImportFoto";
import { useGet } from '../hooks/fetch';
import { Config } from "../libs/config";

const RowAkun = ({title, icon, path}) => {
  const history = useHistory();
  return (
    <a href="#" style={{
      textDecoration: 'none',
      color: "#333"
    }} className={"d-flex w-100"} onClick={(e)=>{e.preventDefault();history.push(path)}}>
      <div className="d-flex justify-content-start w-100" style={{
        borderTopWidth: 1,
        borderBottomWidth: 20,
        borderBottomColor: '#333',
      }}>
        <div>
          {icon}
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between w-100 px-2">
          <div>
            {title}
          </div>
          <div>

              <FaChevronRight />
            
          </div>
        </div>
      </div>
    </a>
  )
}

export default function Akun() {
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/_/api/auth/profile");
  const [auth,setAuth] = useState(null)
  
  useEffect(()=>{
    fetchData();
  },[])

  useEffect(()=>{
    if(!response) return ;
    setAuth(response.data.result)
  },[response])

  return (
    <div className="d-flex flex-column">
      <Header title={"Akun"} showBackButton={true} />
      
      <div className="mx-2 pb-10" style={{
        backgroundColor: "#fff",
        padding: 10,
        borderRadius: 5,
      }}>
        <div className="d-flex flex-column align-items-center">
            {/* <img src = { userIcon } width = { 100 } height = { 100 } alt = "User" / > */}
            <ImportFoto onChange={(e)=>console.log} type={"Profile"} defaultImage={auth?.avatar} showBorder={false} />
            <h4>{auth?.name}</h4>
            <h6 style={{color:"grey"}}>
              {auth?.email}
            </h6>
        </div>
      </div>

      <div className="m-2 pb-10" style={{
        backgroundColor: "#fff",
        padding: 10,
        borderRadius: 5,
      }}>
        <div className="d-flex flex-column align-items-start">
            <small style={{
              fontSize: 12,
              color: 'grey',
              marginBottom: 5,
            }}>Akun</small>
            <RowAkun title={"Ubah Password"} icon={<FaKey />} path={"/akun-ubah-password"} />
            <div className="border-bottom mt-3 mb-3 w-100"></div>
            <RowAkun title={"Tentang Aplikasi"} icon={<FaQuestionCircle />} path={"/about"} />
            <div className="border-bottom mt-3 mb-3 w-100"></div>
            <RowAkun title={"Logout"} icon={<FaPowerOff />} path={"/logout"} />
        </div>
      </div>
    </div>
  )
}