import { createSlice } from '@reduxjs/toolkit'

export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    errorMessage: null,
    errorCode: null
  },
  reducers: {
    setError: (state,action) => {
      state.errorMessage = action.payload.message
      state.errorCode = action.payload.code
    },
    resetError: (state) => {
      state.errorMessage = null
      state.errorCode = null
    },
  },
})

// Action creators are generated for each case reducer function
export const { setError, resetError } = errorSlice.actions

export default errorSlice.reducer
