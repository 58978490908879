import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useGet } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube";

export default function VideoDetail() {
  let { id } = useParams();  
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/api/galleries/"+id);
  useEffect(()=>{
    fetchData()
  },[])
  return (
    <div className="d-flex flex-column w-100">
      <Header title={"Detail Video"} showBackButton={true} />
      <div className="m-2 vh-100 w-100">
        <YouTube videoId={response?.data?.result?.youtube_id} opts={{
          height: '240',
          width: '95%',
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
          },
        }}/>
        <div style={{
          backgroundColor: "white",
          padding: 10,
        }}>
          <div
            dangerouslySetInnerHTML={{
              __html: response?.data?.result?.description
            }}></div>
        </div>
      </div>
    </div>
  )
}