import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null
  },
  reducers: {
    setUser: (state,action) => {
      state.user = action.payload
    },
    resetUser: (state) => {
      state.user = null
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser, resetUser } = authSlice.actions

export default authSlice.reducer
