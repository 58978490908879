import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useGet, usePost } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import DatePicker from 'react-datepicker';
import { FaCalendar, FaFemale, FaHistory, FaMale } from "react-icons/fa";
import ButtonLoading from "../components/ButtonLoading";
import ImportFoto from "../components/ImportFoto";
import Helper from "../libs/helper";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import bayiIcon from "../assets/mybaby.png";
import { toast } from "react-toastify";

export default function BayikuAddRiwayat() {
  let { id } = useParams(); 
  const history = useHistory()
  const [beratBadan,setBeratBadan] = useState(null)
  const [tinggiBadan,setTinggiBadan] = useState(null)
  const [keterangan,setKeterangan] = useState(null)
  const [bulan,setBulan] = useState('')
  const [item,setItem] = useState(null)
  const [{response,error,isLoading},fetchData,setNewUrl] = usePost(Config.env().BASE_URL+"/api/baby-records")
  const onSubmit = (e)=>{
    e.preventDefault()
    fetchData({
      baby_id: item.id,
      week: bulan,
      height: tinggiBadan,
      weight: beratBadan,
    })
  }

  useEffect(()=>{
    axios.get(Config.env().BASE_URL+"/api/babies/"+id,{
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer '+localStorage.getItem("accessToken")
      }
    }).then((res)=>{
      setBulan(res?.data?.result?.pekan_input_terakhir)
      setItem(res.data.result)
    })
  },[])

  useEffect(()=>{
    if(!response) return ;
    toast.success('Berhasil menambah data', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.goBack()
  },[response])

  return (
    <div className="d-flex flex-column">
      <Header title={"Tambah Riwayat"} showBackButton={true} />
      <div className="d-flex bg-white m-2 p-3 border border-rounded rounded">
        <div>
          <img src={item && item.photo ? item.photo.file_url : bayiIcon} width={70} height={70} className="border rounded-circle" />
        </div>
        <div className="ps-3 d-flex flex-column">
          <div className="text-bold">{item?.name}</div>
          <div>
            { item?.gender === 'Laki-Laki' && (<small className="text-sm text-grey"><FaMale />{item?.gender}</small>) }
            { item?.gender !== 'Laki-Laki' && (<small className="text-sm text-grey"><FaFemale />{item?.gender}</small>) }
            <small className="text-sm text-grey ms-2">
              <FaCalendar /> {item?.dob_label} ({item?.umur})
            </small>
          </div>
          <div>
            <small className="text-sm text-grey">
              <FaHistory className="me-1" />Bulan Ke: {item?.pekan_input_terakhir}</small>
          </div>
        </div>
      </div>
      <div className="m-2 vh-100">
        <div className="p-3 bg-white border border-rounded rounded">
          <form onSubmit={(e)=>onSubmit(e)}>
            <div className="mb-3">
                <label className="label ">*Bulan Ke</label>
                <input className="form-control" type="number" value={bulan} onChange={(e)=>{setBulan(e.target.value)}} required step={1} />
              </div>
              <div className="mb-3">
                <label className="label ">*Berat Badan (kg)</label>
                <input className="form-control" type="number" name="email" onChange={(e)=>{setBeratBadan(e.target.value)}} required step={"any"} />
                <small className="help-text">*Gunakan titik sebagai pemisah koma</small>
              </div>
              <div className="mb-3">
                <label className="label ">*Tinggi Badan (cm)</label>
                <input className="form-control" type="number" name="email" onChange={(e)=>{setTinggiBadan(e.target.value)}} required  step={"any"}/>
                <small className="help-text">*Gunakan titik sebagai pemisah koma</small>
              </div> 
              {/* <div className="mb-3">
                <label className="label ">Keterangan</label>
                <textarea className="form-control" onChange={(e)=>setKeterangan(e.target.value)}></textarea>
              </div>  */}
              <div className="mb-3">
                <ButtonLoading title={"Submit"} isLoading={isLoading} className="w-100" />
              </div> 
          </form>
        </div>
      </div>
    </div>
  )
}