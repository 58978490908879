import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useGet } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useHistory, useParams } from "react-router-dom";
import { FaPlus, FaSearch } from "react-icons/fa";
import axios from "axios";
import { Session } from "../libs/session";

const RowList = ({row}) => {
  const history = useHistory();
  return (
    <a href="#" onClick={(e)=>{e.preventDefault();history.push("/chat/"+row.id)}} style={{
      textDecoration: 'none',
    }}>
      <div className="row bg-white p-2 m-2">
        <div className="col-sm-2">
          <img src={row.avatar} style={{
            width: "100%"
          }} />
        </div>
        <div className="col-sm-10">
          <div className="text-bold" style={{
            color: "#333"
          }}>{row.name}</div>
          <div className="text-grey text-sm">{row?.role?.name}</div>
        </div>
      </div>
    </a>
  )
}

export default function Chat() {
  let { id } = useParams();  
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/_/api/chat-conversation/"+id);
  const history = useHistory(); 
  useEffect(()=>{
    setInterval(fetchData(),1000)
  },[])
  const [text,setText] = useState(null)
  const [containerKey,setContainerKey] = useState(0)

  const sendMessage = async() => {
    const token = Session.get("accessToken");
    await axios.post(Config.env().BASE_URL+"/_/api/chat",{
      chat_id: id,
      message: text
    },{
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(async(res)=>{
      await fetchData()
      setContainerKey(containerKey+1)
    })
  }
  return (
    <div className="d-flex flex-column">
      <Header title={"Cari Teman Chatting"} showBackButton={true} />
      <div className="container mt-2">
          <div className="content-wrapper">
              <div className="row gutters">

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card m-0">
                          <div className="row no-gutters">                        
                              <div className="col-sm-12">
                                  <div className="chat-container" key={containerKey}>
                                    {response && response.data && response.data.data && response.data.data.length > 0 &&
                                      <ul className="chat-box chatContainerScroll m-0">
                                        {response.data.data.map((v,i,a)=>{
                                          return (
                                             <li className={v.is_from_me ? 'border-bottom d-flex flex-column align-items-end' : "border-bottom d-flex flex-column align-items-start"} style={{
                                              borderBottom: 1,
                                             }}>
                                              <div className="chat-text">{v.chat}{v.is_from_me}</div>
                                              <div className="text-sm text-grey">
                                                {v.short_date2}
                                              </div>
                                            </li>
                                          // <li className="chat-right border-top pt-2">
                                          //     <div className="chat-hour">08:56 <span className="fa fa-check-circle"></span></div>
                                          //     <div className="chat-text">Hi, Russell
                                          //         <br /> I need more information about Developer Plan.</div>
                                          // </li>
                                          )
                                           
                                        }).reverse()}
                                          
                                      </ul>
                                    }
                                      <div className="form-group mt-3 mb-0">
                                          <textarea className="form-control" rows="3" placeholder="Tulis Pesan" onChange={(e)=>setText(e.target.value)}></textarea>
                                          <button className="btn btn-primary btn-block w-100 mt-2" type="button" onClick={()=>sendMessage()} disabled={!text}>
                                            Kirim
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>

              </div>

          </div>

      </div>
    </div>
  )
}