import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useDelete, useGet, usePost } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useHistory } from "react-router-dom";
import icon from '../assets/icon.jpg';
import { FaBaby, FaCalendar, FaFemale, FaHistory, FaMale, FaPlus } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import { isBrowser } from "react-device-detect";
import { toast } from "react-toastify";

const RowList = ({item,onDelete=(item)=>{}}) => {
  const history = useHistory();
  return (
    <div className="bg-white p-3 border mb-2 rounded d-flex flex-column">
      <div className="d-flex">
        <div>
          <img src={item.photo ? item.photo.file_url : icon} width={70} height={70} className="border rounded-circle" />
        </div>
        <div className="ps-3 d-flex flex-column">
          <div className="text-bold">{item.name}</div>
          <div>
            { item?.gender === 'Laki-Laki' && (<small className="text-sm text-grey"><FaMale />{item.gender}</small>) }
            { item?.gender !== 'Laki-Laki' && (<small className="text-sm text-grey"><FaFemale />{item.gender}</small>) }
            <small className="text-sm text-grey ms-2">
              <FaCalendar /> {item?.dob_label} ({item?.umur})
            </small>
          </div>
          <div>
            <small className="text-sm text-grey">
              <FaHistory className="me-1" />Bulan Ke: {item?.pekan_input_terakhir}</small>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-between">
        <Button variant="primary" size="sm" onClick={()=>history.push('bayiku-add-riwayat/'+item.id)}>
          Tambah Riwayat
        </Button>
        <Button variant="secondary" size="sm" onClick={()=>history.push("bayiku-detail/"+item.id)}>
          Lihat Grafik
        </Button>
        <Button variant="danger" size="sm" onClick={()=>onDelete(item)}>
          Hapus
        </Button>
      </div>
      
    </div>
  )
}

export default function Bayiku() {
  const history = useHistory();
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/api/babies?my_child=true");
  const [{responseDelete,errorDelete,isLoadingDelete},fetchDataDelete,setNewUrlDelete] = useDelete(Config.env().BASE_URL+"/api/babies");
  const [showConfirmDelete,setShowConfirmDelete] = useState(false);
  const [itemSelected,setItemSelected] = useState(null)
  useEffect(()=>{
    fetchData();
  },[])

  const onDelete = (item)=>{
    setItemSelected(item);
    setShowConfirmDelete(true)
  }

  const onDeleteConfirmed = () => {
    setShowConfirmDelete(false)
    fetchDataDelete(itemSelected.id,{
      ids: itemSelected.id+","
    })
  }

  useEffect(()=>{
    if(!responseDelete) return ;
    toast.success('Berhasil menghapus data', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    fetchData();
  },[responseDelete])

  return (
    <div className={'d-flex flex-column'} style={{
      paddingBottom: isBrowser ? 50 : null
    }}>
      <Header title={"Daftar Bayi Saya"} rightChild={
        <a href="#" onClick={(e)=>{e.preventDefault();history.push("bayiku-add")}} className="text-dark text-sm a-free d-flex align-items-center justify-content-center text-center text-middle text-white">
          <FaPlus />
          Tambah Bayi
        </a>
      } />
      <div className="m-2">
        {response?.data?.result?.data && (
          response.data.result.data.map((v,i,a)=>{
            return (
              <RowList key={v.id} item={v} onDelete={(item)=>onDelete(item)} />
            )
          })
        )}
      </div>
      <Modal show={showConfirmDelete} onHide={()=>setShowConfirmDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Anda yakin ingin menghapus data <strong>{itemSelected?.name}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowConfirmDelete(false)}>
            Batal
          </Button>
          <Button variant="primary" onClick={()=>onDeleteConfirmed()}>
            Ya
          </Button>
        </Modal.Footer>
      </Modal>
        <BottomNav active={1} />
    </div>
  )
}