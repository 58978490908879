import React from "react";
import BottomNavigation from 'reactjs-bottom-navigation'
import 'reactjs-bottom-navigation/dist/index.css'
import {FaHome, FaUserCircle } from 'react-icons/fa'
import medis from '../assets/medis.png';
import mom from '../assets/mom.png';
import user from '../assets/profile.png';
import chat from '../assets/chat.png';
import home from '../assets/home.png';
import { useHistory } from "react-router-dom";

export default function BottomNav({active=0}) {
  const history = useHistory();
  const bottomNavItems = [
		{
			title: 'Home',
			icon: <img src={home} width={30} height={30} />,
			activeIcon: <img src={home} width={30} height={30} />,
			path: '/'
		},
		{
			title: 'Perawatan',
			icon: <img src={medis} width={30} height={30} />,
			activeIcon: <img src={medis} width={30} height={30} />,
			path: 'artikel',
		},
		{
			title: 'Chatting',
			icon: <img src={chat} width={30} height={30} />,
			activeIcon: <img src={chat} width={30} height={30} />,
			path: '/chat-friend-list'
		},
    {
			title: 'Komunitas',
			icon: <img src={mom} width={30} height={30} />,
			activeIcon: <img src={mom} width={30} height={30} />,
			path: '/timeline'
		},
		{
			title: 'Akun',
			icon: <img src={user} width={30} height={30} />,
			activeIcon: <img src={user} width={30} height={30} />,
			path: '/akun'
		},
	];
  return (
    <>
      <BottomNavigation
					items={bottomNavItems}
					defaultSelected={active}
					onItemClick={(item) => history.push(item.path)} />
    </>
  )
}