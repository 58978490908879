import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useGet } from '../hooks/fetch';
import mybaby from '../assets/mybaby.png';
import BottomNav from "../components/BottomNav";
import { Config } from "../libs/config";
import { useHistory } from "react-router-dom";
import { FaPlus, FaSearch } from "react-icons/fa";

const RowList = ({row}) => {
  const history = useHistory();
  return (
    <a href="#" onClick={(e)=>{e.preventDefault();history.push("/chat/"+row.id)}} style={{
      textDecoration: 'none',
      color: "#333"
    }}>
      <div className="row bg-white m-2 p-2">
        <div className="col-sm-2">
          <img src={row.chat_avatar} className={"border rounded-circle"} style={{
            aspectRatio:'1/1',
            width: '100%'
          }}/>
        </div>
        <div className="col-sm-10">
          <div className="text-bold">{row.chat_name}</div>
          <div className="text-sm text-grey">
            {row.chat_last_msg}
          </div>
        </div>
      </div>
    </a>
  )
}

export default function ChatFriendList() {
  const [{response,error,isLoading},fetchData,setNewUrl,setNewPayload] = useGet(Config.env().BASE_URL+"/_/api/chat-lists?_fromapp=true");
  const history = useHistory();

  useEffect(()=>{

    fetchData();
  },[])
  return (
    <div className="d-flex flex-column">
      <Header title={"Chatting"} rightChild={
        <a href="#" onClick={(e)=>{e.preventDefault();history.push("chat-search")}} className="text-dark text-sm a-free d-flex align-items-center justify-content-center text-center text-middle text-white">
          <FaSearch />
          Cari
        </a>
      } />

      <div className="m-2">
        {response?.data?.result?.friends && (
          response.data.result.friends.map((v,i,a)=>{
            return (
              <RowList row={v} />
            )
          })
        )}
      </div>
      {response?.data?.result?.friends?.length === 0 &&
        <div className="d-flex align-items-center justify-content-center">
          Belum ada chatting
        </div>
      }
      
        <BottomNav active={1} />
    </div>
  )
}